// import React from "react"
import React, { Component } from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import Footer from "components/shared/Footer";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";

import { graphql } from "gatsby";

import { entryContent, entryHeader, dateClass } from "./index.module.less";


const NewsEntryTemplate = ({
    data: {
      wpPost: { title, content, id, uri, date },
    },
  }) => {
    // return (
    //   <div className={`post-${id}`}>
    //     <h1>{title}</h1>
    //     <div dangerouslySetInnerHTML={{ __html: content }} />
    //   </div>
    // )

    return (
      <>
        <Nav displayLanguageSwitcher={false}/>
        <Meta url={"/news" + uri} defaultTitle={"News | " + title}/>
        <Header>
          <h1>{title}</h1>
        </Header>
        <Layout>
          <div className={[entryContent]}>
            {/* <div className={[entryHeader]}>
              <h2>{title}</h2>
              <span className={[dateClass]}>{date}</span>
              {date}
            </div> */}
  
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </Layout>
        <Footer
          path={
            // <>
            //   news<i>/</i>{uri}
            // </>
           null 
          }
        />
      </>
    );

    
  }

export default NewsEntryTemplate

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
    }
  }
`